import { Controller } from "stimulus"
import SignaturePad from "signature_pad"

export default class extends Controller {
  static targets = ["hidden"]
  canvas = document.querySelector("canvas")
  signaturePad = new SignaturePad(this.canvas)	

  connect(){
    console.log("Hello from signature_controller.js")    
    window.onresize = this.resizeCanvas(this.canvas)
    this.resizeCanvas(this.canvas)
  }

  resizeCanvas(canvas){
		var ratio =  Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext("2d").scale(ratio, ratio)
	}

	clear(event){
		event.preventDefault()
		this.signaturePad.clear()
	}

	save(event){
		this.hiddenTarget.value = this.signaturePad.toDataURL();
	}

}