import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["micro"]
  
  connect(){
    console.log("connecté à speech controller")
  }

  record(){
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    var bodyTarget = document.getElementById("speech-body")

    if (!SpeechRecognition) {
      return
    }

    var recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.lang = 'fr';

    try {
        recognition.start()
    } catch (error) {
        recognition.stop() //already started - toggle
    }

    recognition.onresult = function(event) {
        if (event.results.length > 0) {
            var current = event.results[event.results.length-1][0]
            var result = current.transcript;
            console.log(result)
            //continue here to append to your textarea or input
            bodyTarget.value += result
        }
    }
  }

}