import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["account", "area", "team", "manager"]
  
  connect(){
    console.log("connecté à tickets controller")
  }

  update_area(event){
    let accountId = this.accountTarget.value
    let target = this.areaTarget.id

    get(`/accounts/${accountId}/areas?target=${target}`, {
      responseKind: "turbo-stream"
    })

    get(`/accounts/${accountId}/infos?area_id=${target}`, {
      responseKind: "turbo-stream"
    })
  }

  update_infos(event){
    let accountId = this.accountTarget.value
    let areaId = this.areaTarget.value

    get(`/accounts/${accountId}/infos?area_id=${areaId}`, {
      responseKind: "turbo-stream"
    })
  }

  update_managers(event){
    let teamId = this.teamTarget.value
    let target = this.managerTarget.id

    get(`/teams/${teamId}/managers?target=${target}`, {
      responseKind: "turbo-stream"
    })
  }
}