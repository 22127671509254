import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["account", "margin"]

  connect() {
    console.log("connecté à discounts controller")
  }

  update_margin(event) {
    let accountId = this.accountTarget.value
    let target = this.marginTarget.id

    get(`/accounts/${accountId}/list_margins?target=${target}`, {
      responseKind: "turbo-stream"
    })
  }
}
