import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    "product",
    "puht",
    "quantity",
    "package",
    "total",
    "tax",
    "lease",
    "seats"
  ]
  
  connect(){
    console.log("connecté à item controller")
    this.update_total()
  }

  async update_fields(event){
    var productId = this.productTarget.value
    var puhtTarget = this.puhtTarget

    const response = await get(`/products/${productId}.json`)
    if (response.ok) {
      response.json
      .then(function(value){
        puhtTarget.value = value.puht
      })
    }
  }

  async update_package(event){
    var packageId = this.packageTarget.value
    var puhtTarget = this.puhtTarget

    const response = await get(`/packages/${packageId}.json`)
    if (response.ok) {
      response.json
      .then(function(value){
        puhtTarget.value = value.puht
      })
    }
  }

  update_total(){
    this.totalTarget.innerHTML = this.calc_total()
    this.update_global()
  }

  async update_lease(){
    var leaseAmount = parseFloat(this.leaseTarget.value)
    var seatsAmount = parseFloat(this.seatsTarget.value)
    var packageId = this.packageTarget.value
    var puhtAmount = this.puhtTarget

    const response = await get(`/packages/${packageId}.json`)
    if (response.ok) {
      response.json
      .then(function(value){
        var puht = parseFloat(value.puht)
        var isFluctuate = value.is_fluctuate

        if (isFluctuate) {
          var newPrice = ( puht * seatsAmount ) + leaseAmount
          puhtAmount.value = newPrice.toFixed(2)
        } else {
          var newPrice = puht + leaseAmount
          puhtAmount.value = newPrice.toFixed(2)
        }
        
      })
    }
  }

  calc_total(){
    var puht = parseFloat(this.puhtTarget.value)    
    var quantity = parseFloat(this.quantityTarget.value)    
    // var tax = parseFloat(this.taxTarget.value)    
    var total = puht * quantity
    // var total = puht * quantity * ( 1.0 + (tax / 100.0) )
    return total.toFixed(2)
  }

  update_global(){
    let totals = document.getElementsByClassName("total");
    var total = 0;
    for (let item of totals) {
      let itemPrice = parseFloat(item.textContent);
      total += itemPrice;
    }
    document.getElementById("global").innerText = total.toFixed(2);
  }

}
