import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["sheet"]
  
  connect(){
    console.log("connecté à preparations controller")
  }

  update_properties(event){
    let sheetId = this.sheetTarget.value

    get(`/preparation_sheets/${sheetId}/update_items`, {
      responseKind: "turbo-stream"
    })

  }
}