import { Controller } from "stimulus";
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["searchInput", "nameList"];

  connect() {
    console.log("connecté à search controller");
  }

  async search() {
    const searchInput = this.searchInputTarget.value;
    
    if (searchInput === "") {
      this.nameListTarget.innerHTML = "";
    } else {
      const response = await get(`/searches.json?search_input=${searchInput}`);

      if (response.ok) {
        response.json.then((data) => {
          this.updateNameList(data.accounts);
        });
      }
    }
  }

  updateNameList(accounts) {
    this.nameListTarget.innerHTML = "";

    accounts.forEach((account) => {
      const listItem = document.createElement("li");
      listItem.classList.add("px-2", "py-1", "hover:bg-gray-100", "cursor-pointer");

      // instert <a href="/accounts/1">name</a> in the <li>
      listItem.innerHTML = `<a href="/accounts/${account.id}">${account.name}</a>`;


      // listItem.textContent = name;
      this.nameListTarget.appendChild(listItem);
    });
  }
}
