// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import "controllers"

import "stylesheets/application"
import "alpine-turbo-drive-adapter"
import "alpinejs"
// import resizableGrid from "plugins/resizable"
import Choices from "choices.js"

// import Chartkick
import "chartkick/chart.js"

require("trix")
require("@rails/actiontext")

// document.addEventListener("turbo:load", () => {
//   var table = document.getElementsByTagName('table')[0];
//   if (table){
//     resizableGrid(table);
//   }
// })

document.addEventListener("turbo:load", () => {
  var selects = document.querySelectorAll(".js-choice");
  for (var i = 0; i < selects.length; i++) {
    new Choices(selects[i], {
      loadingText: 'Chargement...',
      noResultsText: 'Aucun résultat',
      noChoicesText: 'Aucun choix correspondant',
      itemSelectText: 'Cliquez pour sélectionner',
      removeItemButton: true
    });
  }
})