import { Controller } from "stimulus"
import { get } from '@rails/request.js'
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["template", "subject", "body", "kind", "recipients"]
  
  connect(){
    console.log("connecté à shipping controller")

    // Init choices.js multiple for recipients
    var recipientSelects = this.recipientsTargets
    for (var i = 0; i < recipientSelects.length; i++) {
      new Choices(recipientSelects[i], {
        loadingText: 'Chargement...',
        noResultsText: 'Aucun résultat',
        noChoicesText: 'Aucun choix correspondant',
        itemSelectText: 'Cliquez pour sélectionner',
        removeItemButton: true
      });
    }
  }

  update_emails(event){
    let kindId = this.kindTarget.value
    let target = this.templateTarget.id

    get(`/email_template_kinds/${kindId}?target=${target}`, {
      responseKind: "turbo-stream"
    })
  }

  async update_subject(event){
    var templateId = this.templateTarget.value
    var subjectTarget = this.subjectTarget
    var bodyTarget = this.bodyTarget

    const response = await get(`/email_templates/${templateId}.json`)
    if (response.ok) {
      response.json
      .then(function(value){
        subjectTarget.value = value.title
        bodyTarget.value = value.body.body
        console.log(value)
      })
    }
  }
}