import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["form"]
  
  connect(){
    var searchAccountID = document.getElementById("search_account_id");
    const choice = new Choices(searchAccountID, {searchResultLimit: 50});
  }

  submit(){
    var form  = this.formTarget
    form.submit() 
  }

}
