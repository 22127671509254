import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form"]

  submit(){
    var form  = this.formTarget
    form.submit() 
  }

}
