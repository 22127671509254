import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    "product",
    "category",
    "tags"
  ]
  
  connect(){
    console.log("connecté à accessories controller")
  }

  update(){
    var target = this.productTarget.id
    var categoryName = this.categoryTarget.value

    get(`/accessories?target=${target}&category_name=${categoryName}`, {
      responseKind: "turbo-stream"
    })
  }
}