import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["links", "template"]
  
  connect(){
  }

  add_association(event){
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

    this.linksTarget.insertAdjacentHTML('beforebegin', content)

    var selects = document.querySelectorAll(".js-choice");
    for (var i = 0; i < selects.length; i++) {
      new Choices(selects[i]);
    }
  }

  remove_association(event){
    event.preventDefault()

    let wrapper = event.target.closest(".nested-fields")
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

}
