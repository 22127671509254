import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "start",
    "end"
  ]
  
  connect(){
    console.log("connecté à meetings controller")
  }

  // if start datetime is changed, update end datetime to start datetime + 1 hour
  update_end(event){
    var startDatetime = new Date(this.startTarget.value)
    var endDatetime = new Date(startDatetime.setHours(startDatetime.getHours() + 5))
    // offset endDatetime to Paris timezone
    endDatetime.setMinutes(endDatetime.getMinutes() + endDatetime.getTimezoneOffset() + 120)
    this.endTarget.value = endDatetime.toISOString().slice(0, 16)
  }

}