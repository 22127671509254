import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["encrypted", "field"]
  
  connect(){
    console.log("Reveal controller connected !")
  }

  show(event) {
    var decryptedValue = this.encryptedTarget.dataset.decrypted
    this.fieldTarget.innerHTML = decryptedValue
  }

  copy(event) {
    var decryptedValue = this.encryptedTarget.dataset.decrypted
    navigator.clipboard.writeText(decryptedValue)
  }

}
