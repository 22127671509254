import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    "product",
    "category",
    "tags",
    "solution"
  ]
  
  connect(){
    console.log(this.solutionTarget.innerHTML)
  }

  update(){
    var target = this.productTarget.id
    var categoryName = this.categoryTarget.value
    var solutionName = this.solutionTarget.innerHTML

    get(`/phones?target=${target}&category_name=${categoryName}&solution_name=${solutionName}`, {
      responseKind: "turbo-stream"
    })
  }
}