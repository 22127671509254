import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    console.log("quality controller connected")
  }

  submit(){
    let selectInput = this.selectTarget
    let accountId = selectInput.dataset.id
    let data = new FormData()
    data.append("account[quality]", selectInput.value)

    if (data.has("account[quality]")){
      Rails.ajax({
        url: `/accounts/${accountId}`,
        type: 'PATCH',
        data: data
      })  
    }

    
  }

}
